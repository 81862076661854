import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from '../../../../../shared/admin.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {Howl, Howler} from 'howler';
const sound = new Howl({
  src: ['https://d257prld83m0y2.cloudfront.net/static-images/bell.aiff'],
  html5: true,
  autoplay: true,
  volume: 1
});
Howler.volume(1);
// Clear listener after first call.
// tslint:disable-next-line:only-arrow-functions
@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
})
export class NavRightComponent implements OnInit {
  adminData: any = {}; // admin profile variable
  getNotificationDetial: any;
  lastNotificationId: any;
  constructor(private service: AdminService, private router: Router) {}

  ngOnInit() {
    this.lastNotificationId = null;
    this.getAdminProfile();
    this.service.updateAdminDataNav.subscribe((value: any) => {
      // console.log("reqd chat value is====>", value);
      if (value) {
        const data = JSON.parse(value);
        // const data = value.json();
        this.adminData.name = data.name;
        if (data.image) {
          this.adminData.profile_image = data.image;
        }
      }
    });
    this.getNotificationEverySec();
    this.getNotifications();
  }

  navigateUser(notificationDetail) {
    switch (notificationDetail.type) {
      case 'register':
        this.router.navigate([`manage/user_mgmt/view-user/${btoa(notificationDetail.value_id)}`]);
        break;
      case 'new_booking':
        this.router.navigate([`manage/transaction-mgmt/view-booking/${btoa(notificationDetail.value_id)}`]);
        break;
      case 'booking_extended_request':
        this.router.navigate([`manage/transaction-mgmt/view-booking/${btoa(notificationDetail.value_id)}`]);
        break;
      case 'booking_cancelled_by_user':
        this.router.navigate([`manage/transaction-mgmt/view-booking/${btoa(notificationDetail.value_id)}`]);
        break;
      case 'product_return':
        this.router.navigate([`manage/transaction-mgmt/view-booking/${btoa(notificationDetail.value_id)}`]);
        break;
      case 'support-ticket':
        if (notificationDetail.room_id) {
          this.router.navigate([`manage/support-mgmt/chat/${btoa(notificationDetail.value_id)}/${btoa(notificationDetail.room_id)}`]);
        } else {
          this.router.navigate([`manage/support-mgmt`]);
        }
        break;
      case 'new_document':
        this.router.navigate([`manage/user_mgmt/view-user/${btoa(notificationDetail.user_id)}`]);
        break;
      case 'document_updated':
        this.router.navigate([`manage/user_mgmt/view-user/${btoa(notificationDetail.user_id)}`]);
        break;
    }
  }

  getAdminProfile() {
    this.service.getAdminProfile().subscribe((res) => {
      this.adminData = res.data;
    });
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['auth/signin']);
    this.service.showMessage({
      message: 'Logged Out Successfully',
    });
  }

  getNotificationEverySec() {
    setInterval(() => {
      this.getNotifications();
    }, 60000);
  }
  getNotifications() {
    const obj = {
      limit: 5,
      offset: 0,
    };
    this.service.getNotifications(obj).subscribe((res: any) => {
      this.getNotificationDetial = res.data.rows;
      if ( this.lastNotificationId && res.data.rows[0].id !=  this.lastNotificationId) {
        sound.play();
      }
      this.lastNotificationId = res.data.rows[0].id;
      // console.log("getNotifiactionDetail", this.getNotificationDetial);
    });
  }


  confirmBox() {
    Swal.fire({
      title: 'Are you sure want to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.logout();
      }
    });
  }
}
