<ul class="navbar-nav ml-auto">
  <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href=" javascript:" data-toggle="dropdown"><i class="icon feather icon-bell"></i></a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notifications</h6>
<!--          <div class="float-right">-->
<!--            <a href="javascript:" class="m-r-10">mark as read</a>-->
<!--            <a href="javascript:">clear all</a>-->
<!--          </div>-->
        </div>
        <perfect-scrollbar [style.max-height]="'300px'">
          <ul class="noti-body">
            <div style="cursor: pointer;" *ngFor="let notification of getNotificationDetial;let first = first; let i = index" (click)="navigateUser(notification)">
              <li class="n-title" *ngIf="first">
                <p class="m-b-0">New</p>
              </li>
              <li class="n-title" *ngIf="i ==1">
                <p class="m-b-0">EARLIER</p>
              </li>
              <li class="notification">
                <div class="media">
                  <img class="img-radius" [src]='notification?.adminNotificationUserDetails?.profile_image'
                    onerror="this.src='assets/images/profile/unknownuser.jpg'" alt="Generic placeholder image">
                  <div class="media-body">
                    <p><strong>{{notification.adminNotificationUserDetails.name | titlecase}}</strong><span
                        class="n-time text-muted"><i class="icon feather icon-clock m-r-10"></i>
                        {{notification.created_at | dateAgo }}</span></p>
                    <p>{{notification.body | titlecase}}</p>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </perfect-scrollbar>
        <div class="noti-footer">
          <a href="javascript:" routerLink='/manage/show-all-notification'>show all</a>
        </div>
      </div>
    </div>
  </li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-user"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
<!--          <img *ngIf="adminData" src="{{adminData?.profile_image}}" class="img-radius" alt="User-Profile-Image">-->
          <span *ngIf="adminData">{{adminData?.name | titlecase }}</span>
          <a href="javascript:" class="dud-logout" title="Logout" (click)="confirmBox()">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li><a href="manage/view-admin" class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li>
<!--          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> My Messages</a></li>-->
<!--          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-lock"></i> Lock Screen</a></li>-->
        </ul>
      </div>
    </div>
  </li>
</ul>
