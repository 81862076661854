import { EventEmitter, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {
  Observable,
  throwError,
  BehaviorSubject,
  timer,
  Subscription,
} from 'rxjs';
import { map, catchError, tap, finalize, switchMap } from 'rxjs/operators';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnimationStyleMetadata } from '@angular/animations';
import { resolveSanitizationFn } from '@angular/compiler/src/render3/view/template';
// 22/march/2022 edited

@Injectable({
  providedIn: 'root',
})
export class AdminService {

  constructor(private httpClient: HttpClient, private snackBar: MatSnackBar) { }

  // ---------------------get admin -------------------------

  get admin_Type(): any {
    if (localStorage != null) {
      const data = localStorage.getItem('adminData');
      return data;
    } else {
     const data = sessionStorage.getItem('adminData');
     return data;
    }


  }
  // invokeFirstComponentFunction: any;

  apiUrl = environment.endPoint; // api endpoint url
  public isLoading = new BehaviorSubject<boolean>(true);
  public updateAdminDataNav: EventEmitter<any> = new EventEmitter<any>();
  checkNewUserEmailExists(value: any) {
    throw new Error('Method not implemented.');
  }

  getToken() {
    if (
      localStorage.getItem('remember_me') &&
      localStorage.getItem('remember_me') == 'yes'
    ) {
      return localStorage.getItem('token');
    }
    if (
      localStorage.getItem('remember_me') &&
      localStorage.getItem('remember_me') == 'no'
    ) {
      return sessionStorage.getItem('token');
    } else {
      return null;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const locale = localStorage.getItem('locale')
      ? localStorage.getItem('locale')
      : 'en'; // -----> By Default `en`
    // Get the auth token from the service.
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Accept-Language': locale,
    };
    // @ts-ignore
    const isExcludeUrl = req.url.includes('storek-dev-media') || req.url.includes('storek-prod-media') || req.url.includes('storek-staging-media') ;
    if (this.getToken() && !isExcludeUrl) {
      // check if admin is logged in and return token
      headers['Authorization'] = this.getToken();
    }

    const authReq = req.clone({
      setHeaders: headers,
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }

  showMessage(object) {
    // show messages
    this.snackBar.open(
      object.message,
      object.action ? object.action : 'CLOSE',
      {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      }
    );
  }

  getQueryParams(obj: any): string {
    const searchParams = new URLSearchParams();
    const params = obj;
    Object.keys(params).forEach((key) => searchParams.append(key, params[key]));
    return searchParams.toString();
  }

  public updateAdminDataOnNavigation(text: string): void {
    // Log it if the console is available (won't be in all browsers)
    if (console && console.log) {
      console.log(`Command entered: ${text}`);
    }

    // Emit an event containing the player's command so we have a log of it in the UI
    this.updateAdminDataNav.emit(text);

    // Add a generic response since we're not actually parsing or responding to input yet
    this.updateAdminDataNav.emit(text);
  }

  adminlogin(data): Observable<any> {
    const API_URL = `${this.apiUrl}/adminLogin`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getAdminProfile(): Observable<any> {
    const API_URL = `${this.apiUrl}/getAdminProfile`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }


  // ---------------------ADD PREMIUM--------------------------

  getaddSubscriptionPlan(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addSubscriptionPlan`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // -------------------PREMIUM-PLUS ---------------------------

  getSubscriptionPlans(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getSubscriptionPlans?limit=${data.limit}&offset=${data.offset}&search=${data.search}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getSubscriptionPlanDetails(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getSubscriptionPlanDetails/${data}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ---------------------Delete Subscription Plan -------------------

  deleteSubscriptionPlan(item_id): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteSubscriptionPlan/${item_id}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editSubscriptionPlan(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editSubscriptionPlan`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // --------------------------ADD ON---------------------------------

  addSubscriptionPlanAddon(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addSubscriptionPlanAddon`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // --------------------------ADD ON LIST ---------------------------

  getSubscriptionPlansAddOns(data?): Observable<any> {
    const API_URL = `${this.apiUrl}/getSubscriptionPlansAddOns?limit=${data.limit}&offset=${data.offset}&search=${data.search}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // --------------------------DELETE ADD ON PLAN -----------------------

  deleteSubscriptionPlanAddOn(data): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteSubscriptionPlanAddOn/${data}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // --------------------------GET ADD ON PLAN DETAIL----------------------

  getSubscriptionPlansAddOnDetails(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getSubscriptionPlansAddOnDetails/${data}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductAddOnDetail(itemId): Observable<any> {
    console.log(`${this.apiUrl}/getProductAddOnDetail/${itemId}`);
    const API_URL = `${this.apiUrl}/getProductAddOnDetail/${itemId}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // --------------------------EDIT ADD ON PLAN DETAIL----------------------

  editSubscriptionPlansAddOn(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editSubscriptionPlansAddOn`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editAddOnPlanDetail(data, itemId): Observable<any> {
    const API_URL = `${this.apiUrl}/editAddOnPlanDetail/${itemId}`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // =========================Email Management=============================

  // -------------------------Get Email Template -----------------------

  getEmailTemplates(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getEmailTemplates`;
    return this.httpClient.get(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // -------------------------Edit Email Template----------------------

  editEmailTemplate(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editEmailTemplate`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // --------------------------CONTACT US ----------------------------

  getContactUs(): Observable<any> {
    const API_URL = `${this.apiUrl}/getContactUs`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // --------------------------Edit CONTACT US------------------------

  editContactUs(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editContactUs`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // --------------------------------ADD BANNER IMAGE ----------------------

  addBannerImage(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addBannerImage`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // --------------------------------GET BANNER IMAGE ---------------------

  getBannerImages(): Observable<any> {
    const API_URL = `${this.apiUrl}/getBannerImages`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ---------------------------------DELETE BANNER IMAGE------------------

  deleteBannerImage(item_id): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteBannerImage/${item_id}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ----------------------------------------------------------------------

  getAllBookings(data): Observable<any> {
    const query = this.getQueryParams(data);
    const API_URL = `${this.apiUrl}/getAllBookings?${query}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ----------------------SUPPORT CATEGORY MANAGEMENT---------------------

  getSupportTicketCategories(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getSupportTicketCategories?limit=${data.limit}&offset=${data.offset}&search=${data.search}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ----------------------ADD Support Category ---------------------------

  addSupportTicketCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addSupportTicketCategory`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // -------------------------Edit Support Category------------------------

  editSupportTicketCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editSupportTicketCategory`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ------------------------Delete Support Categort---------------------

  deleteSupportTicketCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteSupportTicketCategory/${data}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ------------------------Detail Support Category---------------------

  getSupportTicketCategoryDetails(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getSupportTicketCategoryDetails/${data}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ----------------------------SUPPORT MGMT ---------------------------

  getSupportTicketRooms(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getSupportTicketRooms?limit=${data.limit}&offset=${data.offset}&search=${data.search}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ---------------------------CHAT------------------------------------

  getSupportChatRoomDetails(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getSupportChatRoomDetails/${data}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ---------------------------About Us-------------------------------

  getCms(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getCms/${data}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // -------------------------Edit About Us ----------------------------

  editCms(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editCms`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // -------------------------News-letter & Promotion-------------------

  sendNewsLetterOrPromotion(data): Observable<any> {
    const API_URL = `${this.apiUrl}/sendNewsLetterOrPromotion`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  getSentNewsLettersAndPromotions(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getSentNewsLettersAndPromotions?limit=${data.limit}& offset=${data.offset}&type=${data.type}&search=${data.search}`;
    return this.httpClient.get(API_URL).pipe(map((res) => {
      return res;
    }));
  }

  // ---------------------------Sub-Admin -----------------------------

  createSubAdmin(data): Observable<any> {
    const API_URL = `${this.apiUrl}/create/sub/admin`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res;
    }));  }

//  -------------------------Get Sub-admin List -----------------------

  getSubadmin(data): Observable<any> {
    const API_URL = `${this.apiUrl}/get/sub/admin?limit=${data.limit}&offset=${data.offset}&search=${data.search}`;
    return this.httpClient.get(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  // -------------------------Payment Detail --------------------------

  getpaymant(data): Observable<any> {
    const API_URL = `${this.apiUrl}/get/payments?limit=${data.limit}&offset=${data.offset}&search=${data.search}`;
    return this.httpClient.get(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  // ------------------------Update Sub-admin -------------------------

  EditSubadmin(data): Observable<any> {
    const API_URL = `${this.apiUrl}/update/sub/admin`;
    return this.httpClient.patch(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  getSubAdmindetail(data): Observable<any> {
    const API_URL = `${this.apiUrl}/get/sub/admin/${data}`;
    return this.httpClient.get(API_URL).pipe(map((res) => {
      return res;
    }));
  }

  deleteSubadmin(data): Observable<any> {
    const API_URL = `${this.apiUrl}/delete/sub/admin/${data}`;
    return this.httpClient.delete(API_URL).pipe(map((res) => {
      return res;
    }));
  }

  resendCredential(data): Observable<any> {
    const API_URL = `${this.apiUrl}/resend/credentials?id=${data.id}&subject="resend credential"`;
    return this.httpClient.get(API_URL).pipe(map((res) => {
      return res;
    }));
  }

  // -----------------------------Access Privileges--------------------

  getaccessPrivileges(data): Observable<any> {
    const API_URL = `${this.apiUrl}/access/privileges`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res ;
    }));
  }

  GetAccessPrivilegesDetail(sub_admin_id): Observable<any> {
    const API_URL = `${this.apiUrl}/get/access/privileges?sub_admin_id=${sub_admin_id}`;
    return this.httpClient.get(API_URL).pipe(map((res) => {
      return res;
    }));
  }

  getSubAdminDetail(): Observable<any> {
    const API_URL = `${this.apiUrl}/get/access/privileges`;
    return this.httpClient.get(API_URL).pipe(map((res) => {
      return res;
    }));
  }

  // ----------------------------Price Management----------------------

  getPriceManagement(data): Observable<any> {
    const API_URL = `${this.apiUrl}/update/payment/management`;
    return this.httpClient.patch(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  getPrice(): Observable<any> {
    const API_URL = `${this.apiUrl}/getPaymentManagement`;
    return this.httpClient.get(API_URL).pipe(map((res) => {
      return res;
    }));
  }

  // ------------------------Payment Detail -------------------------

  getPaymentDetail(item_id): Observable<any> {
    const API_URL = `${this.apiUrl}/get/payment/${item_id}`;
    return this.httpClient.get(API_URL).pipe(map((res) => {
      return res;
    })
    );
  }

  // -----------------------Refund Payment-------------------------------

  getRefundPAyment(data): Observable<any> {
    const API_URL = `${this.apiUrl}/refund/payment`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res;
    })
    );
  }

  getdeleteNewsLetterOrPromotion(data): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteNewsLetterOrPromotion/${data}`;
    return this.httpClient.delete(API_URL, data).pipe(map((res) => {
      return res;
    })
    );
  }

  getdeleteUser(data): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteUser/${data}`;
    return this.httpClient.delete(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  addBusinessCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addBusinessCategory`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  getBusinessCategories(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getBusinessCategories`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  editBusinessCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editBusinessCategory`;
    return this.httpClient.patch(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  deleteBusinessCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteBusinessCategory/${data}`;
    return this.httpClient.delete(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  getBusinessCategoryDetail(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getBusinessCategory/${data}`;
    return this.httpClient.get(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  // --------------------Transaction & Vat Management -----------------

  getTransactionAndVatDetail(data): Observable<any> {
    const API_URL = `${this.apiUrl}/updateVatAndTransaction`;
    return this.httpClient.patch(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  getVatAndTransaction(): Observable<any> {
    const API_URL = `${this.apiUrl}/getVatAndTransaction`;
    return this.httpClient.get(API_URL).pipe(map((res) => {
      return res;
    }));
  }

  // ----------------------PRODUCT APPROVAL--------------------------
  getproductApproval(data): Observable<any> {
    const API_URL = `${this.apiUrl}/isApprovedProductStatus`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  deleteProduct(data): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteProduct`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }
  // ------------------------------------------------------------------
  getUsers(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getUsers`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getVendors(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getVendors`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getHowItWorks(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getHowItWorks?limit=${data.limit}&offset=${data.offset}&search=${data.search}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editHowItWorks(data?: any): Observable<any> {
    const API_URL = `${this.apiUrl}/editHowItWorks`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getDetailHowItWorks(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getHowItWorksDetails/${data.item_id}`;
    return this.httpClient.get(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  uploadAdminMedia(media: File, path: string): Observable<any> {
    const formData = new FormData();
    formData.append('media', media);
    formData.append('path', path);
    const API_URL = `${this.apiUrl}/uploadAdminMedia`;
    return this.httpClient.post(API_URL, formData).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getTermsCategories(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getTermsCategories`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addTermsCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addTermsCategory`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getTermsAndConditions(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getTermsAndConditions`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getTermsCategoriesForTermsAndConditions(): Observable<any> {
    const API_URL = `${this.apiUrl}/getTermsCategoriesForTermsAndConditions`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addTerms(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addTerms`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getTermDetails(term_id): Observable<any> {
    const API_URL = `${this.apiUrl}/getTermDetails/${term_id}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editTerm(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editTerm`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteTerm(term_id): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteTerm/${term_id}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getTermCategoryDetails(term_category_id): Observable<any> {
    const API_URL = `${this.apiUrl}/getTermCategoryDetails/${term_category_id}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editTermCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editTermCategory`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteTermCategory(term_category_id): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteTermCategory/${term_category_id}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getFaqsCategories(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getFaqsCategories`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addFaqsCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addFaqsCategory`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getFaqsCategoryDetails(faq_category_id): Observable<any> {
    const API_URL = `${this.apiUrl}/getFaqsCategoryDetails/${faq_category_id}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editFaqsCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editFaqsCategory`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteFaqsCategory(faq_category_id): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteFaqsCategory/${faq_category_id}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getFaqsAccordingToFaqCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getFaqsAccordingToFaqCategory`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getFaqsCategoriesForAddingFaqs(): Observable<any> {
    const API_URL = `${this.apiUrl}/getFaqsCategoriesForAddingFaqs`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addFaqs(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addFaqs`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getFaqDetails(faq_id): Observable<any> {
    const API_URL = `${this.apiUrl}/getFaqDetails/${faq_id}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editFaqs(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editFaqs`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteFaqs(faq_id): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteFaqs/${faq_id}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductCategories(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductCategories`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addProductCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addProductCategory`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  changeProductCategoryStatus(data): Observable<any> {
    const API_URL = `${this.apiUrl}/changeProductCategoryStatus`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductCategoryDetails(product_category_id): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductCategoryDetails/${product_category_id}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editProductCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editProductCategory`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteProductCategory(product_category_id): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteProductCategory/${product_category_id}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductSubCategoriesAccordingToCategory(data): Observable<any> {
    const query = this.getQueryParams(data);
    const API_URL = `${this.apiUrl}/getProductSubCategoriesAccordingToCategory/${data.product_category_id}?${query}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ADDING CODE 5/03/2022

  getProductModels(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductModels`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getAllProductCategories(): Observable<any> {
    const API_URL = `${this.apiUrl}/getAllProductCategories`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getAllProducts() : Observable<any> {
    const API_URL = `${this.apiUrl}/getProductNames`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  changeProductAddonStatus(data): Observable<any> {
    const API_URL = `${this.apiUrl}/changeProductAddOnStatus
    `;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addProductSubCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addProductSubCategory`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  changeProductSubCategoryStatus(data): Observable<any> {
    const API_URL = `${this.apiUrl}/changeProductSubCategoryStatus`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductSubCategoryDetails(product_subcategory_id): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductSubCategoryDetails/${product_subcategory_id}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editProductSubCategory(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editProductSubCategory`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteProductSubCategory(product_subcategory_id): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteProductSubCategory/${product_subcategory_id}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getAllProductSubCategories(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getAllProductSubCategories/${data}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteProductModel(product_model_id): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteProductModel/${product_model_id}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  // CODE ENDS HERE 05/03/2022
  // CODE STARTS HERE 07/03/2022
  addProductModel(data) {
    const API_URL = `${this.apiUrl}/addProductModel`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  editProductModel(data) {
    const API_URL = `${this.apiUrl}/editProductModel`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getProductModelDetails(data) {
    const API_URL = `${this.apiUrl}/getProductModelDetails/${data}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  changeProductModelStatus(data): Observable<any> {
    const API_URL = `${this.apiUrl}/changeProductModelStatus`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  // CODE ENDS HERE 08/03/2022

  // ADD DATE 10/03/2022
  addProductBrand(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addProductBrand`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductBrands(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductBrands`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductsAddOns(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductsAddOns`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addProductAddOns(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addProductAddOns`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteProductBrand(product_brand_id): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteProductBrand/${product_brand_id}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editProductBrand(data) {
    const API_URL = `${this.apiUrl}/editProductBrand`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductBrandDetails(data) {
    const API_URL = `${this.apiUrl}/getProductBrandDetails/${data}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // Feature list apis

  getProductFeatures(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductFeatures`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteProductFeature(feature_id): Observable<any> {
    const API_URL = `${this.apiUrl}/deleteProductFeature/${feature_id}`;
    return this.httpClient.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addProductFeature(data): Observable<any> {
    const API_URL = `${this.apiUrl}/addProductFeature`;
    return this.httpClient.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  changeProductFeatureStatus(data) {
    const API_URL = `${this.apiUrl}/changeProductFeatureStatus`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // CODE ENDS HERE 10/03/2022

  changeProductBrandStatus(data): Observable<any> {
    const API_URL = `${this.apiUrl}/changeProductBrandStatus
    `;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  editProductFeature(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editProductFeature`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editUserProfile(data): Observable<any> {
    const API_URL = `${this.apiUrl}/editUserProfile`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  changeDocumentStatus(data): Observable<any> {
    const API_URL = `${this.apiUrl}/changeDocumentStatus/${data.user_document_id}`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductFeatureDetails(data) {
    const API_URL = `${this.apiUrl}/getProductFeatureDetails/${data}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductgetService(obj): Observable<any> {
    const query = this.getQueryParams(obj);
    const API_URL = `${this.apiUrl}/getProducts?${query}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getDashboardStats(obj): Observable<any> {
    const query = this.getQueryParams(obj);
    const API_URL = `${this.apiUrl}/getDashboardStats?${query}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // 15 march edit
  getProductsDetails(product_id): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductDetails/${product_id}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  // end
  getUserDetails(user_id): Observable<any> {
    const API_URL = `${this.apiUrl}/getUserDetails/${user_id}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // -----------------PRODUCT REVIEW---------------------------

  getProductReviews(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductReviews?limit=${data.limit}&offset=${data.offset}&search=${data.search}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // ----------------SELLER REVIEW-----------------------------

  getSellerReviews(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getUserReviews?limit=${data.limit}&offset=${data.offset}&search=${data.search}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addDocument(user_id): Observable<any> {
    const API_URL = `${this.apiUrl}/addDocument`;
    return this.httpClient.post(API_URL, user_id).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // CODE STARTS HERE 11/03/2022

  generateOtpForAdminForgotPassword(emailAddress): Observable<any> {
    const API_URL = `${this.apiUrl}/generateOtpForAdminForgotPassword/${emailAddress}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  verifyOtpForAdminForgotPassword(data) {
    const API_URL = `${this.apiUrl}/verifyOtpForAdminForgotPassword`;
    return this.httpClient.put(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateAdminForgotPassword(data) {
    const API_URL = `${this.apiUrl}/updateAdminForgotPassword`;
    return this.httpClient.put(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  editAdminProfile(data) {
    const API_URL = `${this.apiUrl}/editAdminProfile`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  // editing TODAY 22/MARCH/2022
  checkAdminEmailExists(email) {
    const API_URL = `${this.apiUrl}/checkAdminEmailExists/${email}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // code edit ends here
  checkAdminMobileExists(phone_no) {
    const API_URL = `${this.apiUrl}/checkAdminMobileExists/${phone_no}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  matchAdminOldPassword(oldPassword) {
    const API_URL = `${this.apiUrl}/matchAdminOldPassword/${oldPassword}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  resetAdminPassword(data) {
    const API_URL = `${this.apiUrl}/resetAdminPassword`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getPromotions(obj) {
    const query = this.getQueryParams(obj);
    const API_URL = `${this.apiUrl}/getPromotions?${query}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getAddress(obj) {
    const query = this.getQueryParams(obj);
    const API_URL = `${this.apiUrl}/getAddress?${query}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getPromotionDetails(promotion_id): Observable<any> {
    const API_URL = `${this.apiUrl}/getPromotionDetails/${promotion_id}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getUpdatePromotion(data): Observable<any> {
    const API_URL = `${this.apiUrl}/update/promotion?id=${data.id} & is_approved=${data.is_approved}`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getNotifications(obj) {
    const query = this.getQueryParams(obj);
    const API_URL = `${this.apiUrl}/getNotifications?${query}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  editAdminTerms(obj) {
    const API_URL = `${this.apiUrl}/editAdminTerms`;
    return this.httpClient.patch(API_URL, obj).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getAdminTerms(obj) {
    const query = this.getQueryParams(obj);
    const API_URL = `${this.apiUrl}/getAdminTerms?${query}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getBookings(obj) {
    const query = this.getQueryParams(obj);
    const API_URL = `${this.apiUrl}/getBookings/${obj.user_id}?${query}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getBookingDetails(obj) {
    const API_URL = `${this.apiUrl}/getBookingDetails/${obj}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getBookingBill(booking_id) {
    const API_URL = `${this.apiUrl}/getBookingBill?booking_id=${booking_id}`;
    return this.httpClient.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  markSupportTicketAsResolved(data: any) {
    const API_URL = `${this.apiUrl}/markSupportTicketAsResolved`;
    return this.httpClient.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addBrand(data: any): Observable<any> {
    const API_URL = `${this.apiUrl}/users/addProductBrand`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  AddModals(data: any): Observable<any> {
    const API_URL = `${this.apiUrl}/users/addProductModel`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  getProductSubCategories(category_id: string): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductSubCategoriesAccordingToCategory/${category_id}`;
    return this.httpClient.get(API_URL).pipe(
      map(res => {
        return res;
      }),
    );
  }

  getProductsBrands(data: any): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductsBrands`;
    return this.httpClient.post(API_URL, data).pipe(
      map(res => {
        return res;
      }),
    );
  }

  getProductsModels(data: any): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductsModels`;
    return this.httpClient.post(API_URL, data).pipe(
      map(res => {
        return res;
      }),
    );
  }

  getProductLocations(data: any): Observable<any> {
    const API_URL = `${this.apiUrl}/productLocations`;
    return this.httpClient.get(API_URL, {}).pipe(
      map(res => {
        return res;
      }),
    );
  }

  getProductsFeatures(data: any): Observable<any> {
    const API_URL = `${this.apiUrl}/getProductsFeatures`;
    return this.httpClient.post(API_URL, data).pipe(
      map(res => {
        return res;
      }),
    );
  }

  getFormattedAddressThorughLatLong(data: any): Observable<any> {
    const query = this.getQueryParams(data);
    const API_URL = `${environment.reverseGeoCodingApiPath}?${query}`;
    return this.httpClient.get(API_URL).pipe(
      map(res => {
        return res;
      }),
    );
  }

  uploadUserMedia(media: File, path: string): Observable<any> {
    const formdata = new FormData();
    formdata.append('media', media);
    formdata.append('path', path);
    const API_URL = `${this.apiUrl}/uploadUserMedia`;
    return this.httpClient.post(API_URL, formdata).pipe(
      map(res => {
        return res;
      }),
    );
  }

  addProduct(data: any): Observable<any> {
    const API_URL = `${this.apiUrl}/addProduct`;
    return this.httpClient.post(API_URL, data).pipe(
      map(res => {
        return res;
      }),
    );
  }

  editProduct(data: any, product_id: any): Observable<any> {
    const API_URL = `${this.apiUrl}/editProduct/${product_id}`;
    return this.httpClient.patch(API_URL, data).pipe(
      map(res => {
        return res;
      }),
    );
  }

  getLatLongViaIP(): Observable<any> {
    const API_URL = `${environment.latLongByIpApi}`;
    return this.httpClient.get(API_URL).pipe(
      map(res => {
        return res;
      }),
    );
  }


  changeBookingStatus(data): Observable<any> {
    const API_URL = `${this.apiUrl}/changeBookingStatus`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  changeModificationRequestStatus(data): Observable<any> {
    const API_URL = `${this.apiUrl}/changeModificationRequestStatus`;
    return this.httpClient.post(API_URL, data).pipe(map((res) => {
      return res;
    }));
  }

  downloadPDF(data): Observable<any> {
    const API_URL = `${this.apiUrl}/getBookingBill?booking_id=${data.booking_id}`;
    return this.httpClient.get(API_URL).pipe(
      map(res => {
        return res;
      }),
    );
  }

  requestNetworkAccessToken(): Observable<any> {
    const API_URL = `${this.apiUrl}/generateNetworkPaymentUrl`;
    return this.httpClient.post(API_URL, {}).pipe(
      map(res => {
        return res;
      }),
    );
  }

  captureFund(data): Observable<any> {
    const API_URL = `${this.apiUrl}/captureNetworkFund`;
    return this.httpClient.post(API_URL, data).pipe(
      map(res => {
        return res;
      }),
    );
  }

  chargeAdditionalFee(data): Observable<any> {
    const API_URL = `${this.apiUrl}/chargeAdditionalFee`;
    return this.httpClient.post(API_URL, data).pipe(
      map(res => {
        return res;
      }),
    );
  }

  createRefundPayment(data): Observable<any> {
    const API_URL = `${this.apiUrl}/createRefundPayment`;
    return this.httpClient.post(API_URL, data).pipe(
      map(res => {
        return res;
      }),
    );
  }

  cancelNetworkCapture(data): Observable<any> {
    const API_URL = `${this.apiUrl}/cancelNetworkCapture`;
    return this.httpClient.post(API_URL, data).pipe(
      map(res => {
        return res;
      }),
    );
  }


  generateSignedUrl(data): Observable<any> {
    const API_URL = `${this.apiUrl}/s3-signed-urls`;
    return this.httpClient.post(API_URL, data).pipe(
      map(res => {
        return res;
      }),
    );
  }


}
