import { splitNsName } from '@angular/compiler';
import { Injectable, OnInit } from '@angular/core';
import { AdminService } from 'src/app/shared/admin.service';
export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}




@Injectable()
export class NavigationItem implements Navigation, OnInit {

  admin_type: string;
  manage_userPrivileges: any;
  count;
  NavigationItems: any[] = [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'feather icon-monitor',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/dashboard/default',
          icon: 'feather icon-home',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: `Products`,
          type: 'collapse',
          url: `/manage/user_mgmt`,
          icon: 'feather icon-check-square',
          children: [
            {
              id: 'dashboard',
              title: 'Add Product',
              type: 'item',
              url: '/manage/product'
            },
            {
              id: 'dashboard',
                  title: 'Product List',
                  type: 'item',
                  url: '/manage/product-aproval'
            }
          ]
        },
      ],
    },
  ];
  nav = [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'feather icon-monitor',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          url: '/dashboard/default',
          icon: 'feather icon-home',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'User Mgmt',
          type: 'item',
          url: '/manage/user_mgmt',
          icon: 'feather icon-user',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Sub-Admin Mgmt',
          type: 'item',
          url: '/manage/sub_admin_mgmt',
          icon: 'feather icon-briefcase',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Access Privilege',
          type: 'item',
          url: '/manage/access_privileges',
          icon: 'feather icon-airplay',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Services Mgmt',
          type: 'item',
          url: '/manage/services-mgmt',
          icon: 'feather icon-grid',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Product Category',
          type: 'item',
          url: '/manage/category_mgmt',
          icon: 'feather icon-grid',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Service Category',
          type: 'item',
          url: '/manage/service-category',
          icon: 'feather icon-grid',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Subscription Plans',
          type: 'item',
          url: '/manage/premium-plans',
          icon: 'feather icon-award',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Add On Plan',
          type: 'item',
          url: '/manage/add-on-plans',
          icon: 'feather icon-plus-circle',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Marketplace Mgmt',
          type: 'collapse',
          icon: 'feather icon-shopping-cart',
          children: [
            {
              id: 'dashboard',
              title: 'Product Features',
              type: 'item',
              url: '/manage/features-list',
            },
            {
              id: 'dashboard',
              title: 'Product Model',
              type: 'item',
              url: '/manage/brand-model',
            },
            {
              id: 'dashboard',
              title: 'Product Brand',
              type: 'item',
              url: '/manage/brands-list',
            },
          ],
        },
        {
          id: 'dashboard',
          title: 'Order Bookings',
          type: 'item',
          url: '/manage/payment-mgmt',
          icon: 'feather icon-credit-card',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Transaction Mgmt',
          type: 'item',
          url: '/manage/transaction-mgmt',
          icon: 'feather icon-crosshair',
          classes: 'nav-item',
        },
        // {
        //   id: 'dashboard',
        //   title: 'Reviews Mgmt',
        //   type: 'collapse',
        //   icon: 'feather icon-eye',
        //   children: [
        //     {
        //       id: 'dashboard',
        //       title: 'Product Reviews',
        //       type: 'item',
        //       url: '/manage/product-reviews',
        //     },
        //     {
        //       id: 'dashboard',
        //       title: 'Seller Reviews',
        //       type: 'item',
        //       url: '/manage/seller-reviews',
        //     },
        //   ],
        // },
        {
          id: 'dashboard',
          title: 'Report Mgmt',
          type: 'item',
          url: '/manage/report-mgmt',
          icon: 'feather icon-clipboard',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Support Mgmt',
          type: 'item',
          url: '/manage/support-mgmt',
          icon: 'feather icon-command',
          classes: 'nav-item',
        },
        // {
        //   id: 'dashboard',
        //   title: 'Price Mgmt',
        //   type: 'item',
        //   url: '/manage/price-management',
        //   icon: 'feather icon-command',
        //   classes: 'nav-item',
        // },
        {
          id: 'dashboard',
          title: 'Support Category',
          type: 'item',
          url: '/manage/support-cat-mgmt',
          icon: 'feather icon-home',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Email Mgmt',
          type: 'item',
          url: '/manage/email-mgmt',
          icon: 'feather icon-mail',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Contact-Us Mgmt',
          type: 'item',
          url: '/manage/contact-us',
          icon: 'feather icon-edit-2',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Ads Mgmt',
          type: 'item',
          url: '/manage/banner-image',
          icon: 'feather icon-home',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'News Letter',
          type: 'item',
          url: '/manage/news-letter',
          icon: 'feather icon-home',
          classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Promotions & Offer',
          type: 'item',
          url: '/manage/promotions-and-offer',
          icon: 'feather icon-home',
          classes: 'nav-item',
        },
        {
            id: 'dashboard',
            title: 'Promotions',
            type: 'item',
            url: '/manage/promotion',
            icon: 'feather icon-home',
            classes: 'nav-item',
        },
        {
          id: 'dashboard',
          title: 'Content Mgmt',
          type: 'collapse',
          icon: 'feather icon-edit-2',
          children: [
            {
              id: 'dashboard',
              title: 'T & C Management',
              type: 'collapse',
              children: [
                {
                  id: 'dashboard',
                  title: 'T & C Categories',
                  type: 'item',
                  url: '/manage/terms-categories',
                },
                // {
                //   id: 'dashboard',
                //   title: 'T & C',
                //   type: 'item',
                //   url: '/manage/terms',
                // },
              ],
            },
            {
              id: 'dashboard',
              title: 'FAQs Management',
              type: 'item',
              url: '/manage/faq-categories',
            },
            {
              id: 'dashboard',
              title: 'About Us',
              type: 'item',
              url: '/manage/about-us',
            },
            {
              id: 'dashboard',
              title: 'Privacy-Policy',
              type: 'item',
              url: '/manage/privacy-policy',
            },
            {
              id: 'dashboard',
              title: 'General Management',
              type: 'item',
              url: '/manage/general-management',
            },
            {
              id: 'dashboard',
              title: 'How It Works',
              type: 'item',
              url: '/manage/how-it-works',
            },
          ],
        },
      ],
    },
  ];

  constructor(private service: AdminService) {
    this.getAdminProfile();
    this.getSubAdminDetail();
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }



  getAdminProfile() {
    this.service.getAdminProfile().subscribe((res) => {
      this.admin_type = res.data?.admin_type;
      this.count = 5;
      this.service.getSubAdminDetail().subscribe((res) => {
        this.manage_userPrivileges = res.data;

        if (this.admin_type == 'admin') {
          this.NavigationItems[0]?.children.push(
            {
              id: 'dashboard',
              title: 'User Mgmt',
              type: 'collapse',
              url: `/manage/user_mgmt`,
              icon: 'feather icon-user',
              classes: 'nav-item',
              children: [
                {
                  id: 'dashboard',
                  title: 'User',
                  type: 'item',
                  url: '/manage/user',
                },
                {
                  id: 'dashboard',
                  title: 'Vendor',
                  type: 'item',
                  url: '/manage/vender',
                  hidden: true
                }
              ]
            },

            {
              id: 'dashboard',
              title: 'Sub-Admin Mgmt',
              type: 'collapse',
              url: `/manage/user_mgmt`,
              icon: 'feather icon-user',
              classes: 'nav-item',
              hidden: true,
              children: [
                {
                  id: 'dashboard',
                  title: 'Sub-Admin',
                  type: 'item',
                  url: '/manage/sub_admin_mgmt',
                },
                {
                  id: 'dashboard',
                  title: 'Access Privilege',
                  type: 'item',
                  url: '/manage/access_privileges',
                }
              ]
            },
            {
              id: 'dashboard',
              title: 'Marketplace Mgmt',
              type: 'collapse',
              icon: 'feather icon-shopping-cart',
              children: [
                {
                  id: 'dashboard',
                  title: 'Product Features',
                  type: 'item',
                  url: '/manage/features-list',
                },
                {
                  id: 'dashboard',
                  title: 'Product Model',
                  type: 'item',
                  url: '/manage/brand-model',
                },
                {
                  id: 'dashboard',
                  title: 'Product Brand',
                  type: 'item',
                  url: '/manage/brands-list',
                },
                {
                  id: 'dashboard',
                  title: 'Product Add On',
                  type: 'item',
                  url: '/manage/product-add-on',
                },
                {
                  id: 'dashboard',
                  title: 'Product Category',
                  type: 'item',
                  url: '/manage/category_mgmt',
                },
                {
                  id: 'dashboard',
                  title: 'Service Category',
                  type: 'item',
                  url: '/manage/service-category',
                  hidden: true
                },
                {
                  id: 'dashboard',
                  title: 'Business Category',
                  type: 'item',
                  url: '/manage/business-category',
                  hidden: true
                },
                {
                  id: 'dashboard',
                  title: 'Support Category',
                  type: 'item',
                  url: '/manage/support-cat-mgmt',
                },
                {
                  id: 'dashboard',
                  title: 'Subscription Plans',
                  type: 'item',
                  url: '/manage/premium-plans',
                  hidden: true
                },
                {
                  id: 'dashboard',
                  title: 'Add On Plan',
                  type: 'item',
                  url: '/manage/add-on-plans',
                  hidden: true
                }
              ],
            },
            {
              id: 'dashboard',
              title: 'Order Bookings',
              type: 'collapse',
              icon: 'feather icon-credit-card',
              children: [
                {
                  id: 'dashboard',
                  title: 'Orders List',
                  type: 'item',
                  url: '/manage/transaction-mgmt',
                },
                {
                  id: 'dashboard',
                  title: 'Transaction Details',
                  type: 'item',
                  url: '/manage/payment-mgmt',
                },
                // {
                //   id: 'dashboard',
                //   title: 'Price Mgmt',
                //   type: 'item',
                //   url: '/manage/price-management',
                // },
                {
                  id: 'dashboard',
                  title: 'Tran & Vat Mgmt',
                  type: 'item',
                  url: '/manage/trans-and-vat',
                },
              ]
            },
            // {
            //   id: 'dashboard',
            //   title: 'Reviews Mgmt',
            //   type: 'collapse',
            //   icon: 'feather icon-edit-2',
            //   children: [
            //     {
            //       id: 'dashboard',
            //       title: 'Product Reviews',
            //       type: 'item',
            //       url: '/manage/product-reviews',
            //     },
            //     // {
            //     //   id: "dashboard",
            //     //   title: "Seller Reviews",
            //     //   type: "item",
            //     //   url: "/manage/seller-reviews",
            //     // },
            //   ],
            // },
            {
              id: 'dashboard',
              title: 'Support Mgmt',
              type: 'item',
              url: '/manage/support-mgmt',
              icon: 'feather icon-command',
              classes: 'nav-item',
            },
            {
              id: 'dashboard',
              title: 'Content Mgmt',
              type: 'collapse',
              icon: 'feather icon-edit',
              children: [
                {
                  id: 'dashboard',
                  title: 'T & C Management',
                  type: 'item',
                  url: '/manage/terms-categories',
                  // children: [
                  //   {
                  //     id: 'dashboard',
                  //     title: 'T & C Categories',
                  //     type: 'item',
                  //     url: '/manage/terms-categories',
                  //   },
                    // {
                    //   id: 'dashboard',
                    //   title: 'T & C',
                    //   type: 'item',
                    //   url: '/manage/terms',
                    // },
                  // ],
                },

                {
                  id: 'dashboard',
                  title: 'Website Content Mgmt',
                  type: 'collapse',
                  children: [
                    {
                      id: 'dashboard',
                      title: 'About Us',
                      type: 'item',
                      url: '/manage/about-us',
                    },
                    // {
                    //   id: 'dashboard',
                    //   title: 'Privacy Policy',
                    //   type: 'item',
                    //   url: '/manage/privacy-policy',
                    // },
                    {
                      id: 'dashboard',
                      title: 'How It Works',
                      type: 'item',
                      url: '/manage/how-it-works',
                    },
                    {
                      id: 'dashboard',
                      title: 'Contact-Us Mgmt',
                      type: 'item',
                      url: '/manage/contact-us',
                    },
                  ],
                },

                // {
                //   id: "dashboard",
                //   title: "About Us",
                //   type: "item",
                //   url: "/manage/about-us",
                // },
                {
                  id: 'dashboard',
                  title: 'FAQs Management',
                  type: 'item',
                  url: '/manage/faq-categories',
                },
                // {
                //   id: "dashboard",
                //   title: "Privacy-Policy",
                //   type: "item",
                //   url: "/manage/privacy-policy",
                // },
                // {
                //   id: "dashboard",
                //   title: "How It Works",
                //   type: "item",
                //   url: "/manage/how-it-works",
                // },
                // {
                //   id: "dashboard",
                //   title: "News Letter",
                //   type: "item",
                //   url: "/manage/news-letter",
                // },
                // {
                //   id: "dashboard",
                //   title: "Ads Mgmt",
                //   type: "item",
                //   url: "/manage/banner-image",
                // },
                // {
                //   id: "dashboard",
                //   title: "Contact-Us Mgmt",
                //   type: "item",
                //   url: "/manage/contact-us",
                // },
                // {
                //   id: "dashboard",
                //   title: "Email Mgmt",
                //   type: "item",
                //   url: "/manage/email-mgmt",
                // },
                {
                  id: 'dashboard',
                  title: 'General Management',
                  type: 'item',
                  url: '/manage/general-management',
                },
                {
                  id: 'dashboard',
                  title: 'Ads Mgmt',
                  type: 'item',
                  url: '/manage/banner-image',
                },

              ],
            },
          );
        } else if (this.admin_type == 'subadmin') {
          if (this.manage_userPrivileges?.manage_user == 1) {
            this.NavigationItems[0]?.children.push({
              id: 'dashboard',
              title: 'User Mgmt',
              type: 'item',
              url: '/manage/user_mgmt',
              icon: 'feather icon-user',
              classes: 'nav-item',
            });
          }
          if (this.manage_userPrivileges?.content == 1) {
            this.NavigationItems[0]?.children.push({
              id: 'dashboard',
              title: 'Content Mgmt',
              type: 'collapse',
              icon: 'feather icon-edit-2',
              children: [
                // {
                //   id: 'dashboard',
                //   title: 'T & C Management',
                //   type: 'collapse',
                //   children: [
                //     {
                //       id: 'dashboard',
                //       title: 'T & C Categories',
                //       type: 'item',
                //       url: '/manage/terms-categories',
                //     },
                //     {
                //       id: 'dashboard',
                //       title: 'T & C',
                //       type: 'item',
                //       url: '/manage/terms',
                //     },
                //   ],
                // },
                {
                  id: 'dashboard',
                  title: 'FAQs Management',
                  type: 'item',
                  url: '/manage/faq-categories',
                },
                {
                  id: 'dashboard',
                  title: 'About Us',
                  type: 'item',
                  url: '/manage/about-us',
                },
                {
                  id: 'dashboard',
                  title: 'Privacy-Policy',
                  type: 'item',
                  url: '/manage/privacy-policy',
                },
                {
                  id: 'dashboard',
                  title: 'General Management',
                  type: 'item',
                  url: '/manage/general-management',
                },
                {
                  id: 'dashboard',
                  title: 'How It Works',
                  type: 'item',
                  url: '/manage/how-it-works',
                },
              ],
            });
          }
          if (this.manage_userPrivileges?.listing_chat == 1) {
            this.NavigationItems[0]?.children.push({
              id: 'dashboard',
              title: 'Support Mgmt',
              type: 'item',
              url: '/manage/support-management',
              icon: 'feather icon-command',
              classes: 'nav-item',
            }, );
          }
          if (this.manage_userPrivileges?.subscription == 1) {
            this.NavigationItems[0]?.children.push({
              id: 'dashboard',
              title: 'Subscription Plans',
              type: 'item',
              url: '/manage/premium-plans',
              icon: 'feather icon-award',
              classes: 'nav-item',
            }, );
          }
        }


      });
    });

    // this.NavigationItems.map((val)=> {return (val.title == 'Aproval')})
    console.log(this.NavigationItems);
    return this.NavigationItems;
  }

  getSubAdminDetail() {
    this.service.getSubAdminDetail().subscribe((res) => {
      this.manage_userPrivileges = res.data;
    });
  }


  public get() {
    return this.NavigationItems;
  }
}
