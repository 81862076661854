// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const url = window.location.host;
export const environment = (() => {
  if (url.includes('staging-admin.storek.ae')) {
    return {
      endPoint: 'https://admin-staging.storek.ae/admin',
      MAX_IMAGE_SIZE: 5120,
      MAX_VIDEO_SIZE: 51000,
      cloudfrontURL: 'https://staging-media.storek.ae',
      bellUrl: 'https://d257prld83m0y2.cloudfront.net/static-images/bell.aiff',
      socketPath: 'wss://staging-socket.storek.ae',
      productMediaPath: '',
      reverseGeoCodingApiPath: 'https://nominatim.openstreetmap.org/reverse.php',
      latLongByIpApi: 'https://ipapi.co/json/',
      production: true
    };
  } else if (url.includes('admin.storek.ae')) {
    return {
      endPoint: 'https://admin-api.storek.ae/admin',
      MAX_IMAGE_SIZE: 5120,
      MAX_VIDEO_SIZE: 51000,
      cloudfrontURL: 'https://prod-media.storek.ae',
      bellUrl: 'https://d257prld83m0y2.cloudfront.net/static-images/bell.aiff',
      socketPath: 'wss://socket.server.storek.ae',
      productMediaPath: '',
      reverseGeoCodingApiPath: 'https://nominatim.openstreetmap.org/reverse.php',
      latLongByIpApi: 'https://ipapi.co/json/',
      production: true
    };
  } else {
    // Use production URLs for the default case
    return {
      // endPoint: 'https://admin-api.storek.ae/admin',
      endPoint: 'http://localhost:3018/admin',
      // endPoint: 'https://admin-staging.storek.ae/admin',
      MAX_IMAGE_SIZE: 5120,
      MAX_VIDEO_SIZE: 51000,
      cloudfrontURL: 'https://staging-media.storek.ae',
      bellUrl: 'https://d257prld83m0y2.cloudfront.net/static-images/bell.aiff',
      socketPath: 'wss://socket.server.storek.ae',
      productMediaPath: '',
      reverseGeoCodingApiPath: 'https://nominatim.openstreetmap.org/reverse.php',
      latLongByIpApi: 'https://ipapi.co/json/',
      production: true
    };
  }
})();


// export const environment: any = {
//
//   production: false,
//   // endPoint: "https://api.storek.ae:2018/admin",
//   // endPoint: 'http://51.112.24.196:3018/admin', // this is the api endpoint where all rest requests will be made
//
//   // endPoint: 'https://admin-api.storek.ae/admin',
//   // endPoint: 'https://admin-staging.storek.ae/admin',
//   endPoint: 'http://localhost:3018/admin',
//   MAX_IMAGE_SIZE: 5120,
//   MAX_VIDEO_SIZE: 51000,
//   // socketPath: 'ws://localhost:3016',
//   cloudfrontURL: 'https://staging-media.storek.ae',
//   // cloudfrontURL: 'https://prod-media.storek.ae',
//   bellUrl: 'https://d257prld83m0y2.cloudfront.net/static-images/bell.aiff',
//   socketPath: 'wss://staging-socket.storek.ae',
//   // socketPath: 'wss://socket.server.storek.ae',
//   productMediaPath : '',
//   reverseGeoCodingApiPath: 'https://nominatim.openstreetmap.org/reverse.php',
//   latLongByIpApi: 'https://ipapi.co/json/',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
